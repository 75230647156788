<template>
  <v-card
    :disabled="disabled"
    flat
    class="px-5 ml-5 mt-8"
  >
    <v-alert
      text
      outlined
      type="info"
      v-if="brandUserRolesAdminAccessErrorMessage"
      class="mt-4 col-12 text-center"
    >
      {{ brandUserRolesAdminAccessErrorMessage }}
    </v-alert>
    <v-row>
      <v-col class="col-12 col-md-6">
        <v-card-title>{{ $t('EditBrandInformations') }}</v-card-title>
        <v-card-text>
          <v-form ref="brandForm">
            <v-text-field
              v-if="brand"
              v-model="brand.name"
              :label="$t('name')"
              :rules="[(value) => !!value.trim() || this.$t('nameRules')]"
              outlined
              required
              :loading="loading"
              :disabled="loading || brandPurchaseOrderModified || brandAddressModified"
              @change="updateName"
            >
            </v-text-field>
            <v-card-actions>
              <v-btn
                color="error"
                class="text-none mr-2"
                :loading="loading"
                :disabled="!brandNameModified || loading"
                :ripple="false"
                @click="getBrand"
              >
                <v-icon small class="mr-1">
                  fa fa-ban
                </v-icon>
                {{ $t('Cancel') }}
              </v-btn>
              <v-btn
                color="success"
                class="text-none"
                @click="modifyBrand"
                :loading="loading"
                :disabled="!brandNameModified || loading"
                :ripple="false"
              >
                <v-icon small class="mr-1">
                  fa fa-check
                </v-icon>
                {{ $t('Submit') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
        <!---
          ---
          --- PURCHASE ORDER
          ---
          -->
        <v-card-title>{{ $t('EditBrandDefaultPurchaseOrder') }}</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              v-if="brand"
              v-model="brand.purchaseOrder"
              type="text"
              outlined
              :label="$t('PurchaseOrder')"
              :loading="loading"
              :disabled="loading || brandNameModified || brandAddressModified"
              @change="updatePurchaseOrder"
              :maxlength="29"
              counter
            >
            </v-text-field>
          </v-form>
          <v-card-actions>
            <v-btn
              color="error"
              class="text-none mr-2"
              :loading="loading"
              :disabled="!brandPurchaseOrderModified || loading"
              :ripple="false"
              @click="getBrand"
            >
              <v-icon small class="mr-1">
                fa fa-ban
              </v-icon> {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              color="success"
              class="text-none"
              :loading="loading"
              :disabled="!brandPurchaseOrderModified || loading"
              :ripple="false"
              @click="modifyPurchaseOrder"
            >
              <v-icon small class="mr-1">
                fa fa-check
              </v-icon>
              {{ $t('Submit') }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-col>
      <!--
        ---
        ---
        ---- BILLING ADDRESS
        ---
        ---
        -->
      <v-col class="col-12 col-md-6">
        <v-card flat>
          <v-card-title>{{ $t('EditBrandBillingAddress') }}</v-card-title>
          <v-card-text>
            <formAddress
              v-if="brand"
              :disabled="loading || brandNameModified || brandPurchaseOrderModified"
              :address="brand.address"
              ref="addressForm"
              :is-valid-v-a-t="isValidVAT"
              :restricted-country-list="[]"
              :required-company="true"
              :required-zip-code="true"
              :required-phone-number="true"
              :required-v-a-tnumber="true"
              :allow-to-configure-v-a-t-later="true"
              @validateVAT="validateVAT"
              @updated="updateAddress"
            >
            </formAddress>
            <v-card-actions>
              <v-btn
                color="error"
                class="text-none mr-2"
                :loading="loading"
                :disabled="!brandAddressModified || loading"
                :ripple="false"
                @click="getBrand"
              >
                <v-icon small class="mr-1">
                  fa fa-ban
                </v-icon>
                {{ $t('Cancel') }}
              </v-btn>
              <v-btn
                color="success"
                class="text-none"
                :loading="loading"
                :disabled="!brandAddressModified || loading"
                :ripple="false"
                @click="modifyBrandAddress"
              >
                <v-icon small class="mr-1">
                  fa fa-check
                </v-icon>
                {{ $t('Submit') }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { EventBus, ApiErrorParser, FormAddress } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageAdministrationGeneral.i18n.json');

export default {
  name: 'PageAdministrationGeneral',
  components: { FormAddress },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      brandUserRolesAdminAccessErrorMessage: null,
      loading: false,
      disabled: true,
      brand: null,
      brandNameModified: false,
      brandPurchaseOrderModified: false,
      brandAddressModified: false,
      isValidVAT: false,
    };
  },
  created() {
    if (!this.$appConfig.brand) {
      EventBus.$emit('reloadBrand');
    }
    this.$apiInstance
      .getBrandUserRoles(
        this.$appConfig.currentBrand,
        this.$appConfig.user._id
      )
      .then(
        (brandUserRolesData) => {
          this.brandUserRoles = brandUserRolesData;
          /**
           * GET BRAND
           */
          this.getBrand();
        },
        (error) => {
          this.brandUserRolesErrorMessage = ApiErrorParser.parser(error);
        }
      );
  },
  methods: {
    getBrand() {
      /**
       * GET BRAND
       */
      this.loading = true;
      this.$apiInstance
        .getBrand(this.$appConfig.currentBrand)
        .then(
          (brandData) => {
            this.brand = brandData;
            this.name = null,
            this.purchaseOrder = null;
            this.address = null,
            this.disabled = false;
            this.brandNameModified = false;
            this.brandPurchaseOrderModified = false;
            this.brandAddressModified = false;
            /**
             * GET BRAND USER ROLES
             */           
            if (!this.brand?.roles?.admin?.includes(this.$appConfig.user._id) && !this.brandUserRoles.includes('admin')) {
              this.brandUserRolesAdminAccessErrorMessage = this.$t(
                'needToBeAdmin'
              );
              this.disabled = true;
            }
          },
          (error) => {
            this.$notification.notify('ERROR',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * MODIFY BRAND
     */
    modifyBrand() {
      if (this.$refs.brandForm.validate()) {
        this.loading = true;
        const modifyBrandBody = new this.$BcmModel.ModifyBrandBody(
          this.brand.name
        );
        this.$apiInstance
          .modifyBrand(this.$appConfig.currentBrand, modifyBrandBody)
          .then(
            (modifyBrandData) => {
              this.getBrand();
              this.$notification.notify('SUCCESS', this.$t('NameSuccessfullyModified'));
              EventBus.$emit('reloadBrand');
            },
            (error) => {
              /**
               * ERROR MODIFY BRAND
               */
              this.$notification.notify('ERROR',this.$t(ApiErrorParser.parse(error)));
            }
          )
          .finally(() => {
            this.loading = false;
          });
      }
    },
    /**
     * Validate VAT
     */
    async validateVAT(countryCode, vat) {
      this.isValidVAT = null;
      this.isValidVAT = await this.$apiInstance.validateEuropeanVAT(countryCode, vat)
        .then((data) => {
          return true;
        }, (error) => {
          return false;
        });
    },
    /**
     * MODIFY BRAND ADDRESS
     */
    modifyBrandAddress() {
      if (this.$refs.addressForm.validate()) {
        this.loading = true;
        const address = Object.assign(
          new this.$BcmModel.Address(),
          this.brand.address
        );
        if(!address.VATnumber) { // If null, VAT is not added and will be consider to configured later
          delete address.VATnumber;
        }
        this.$apiInstance
          .modifyBrandAddress(this.$appConfig.currentBrand, address)
          .then(
            (brandAddressData) => {
              this.getBrand();
              /**
               * Component BeelseNotifications used
               */
              this.$notification.notify('SUCCESS',this.$t('AddressSuccessfullyModified'));
              EventBus.$emit('reloadBrand');
            },
            (error) => {
              /**
               * ERROR MODIFY BRAND ADDRESS
               */
              this.$notification.notify('ERROR',this.$t(ApiErrorParser.parse(error)));
            }
          )
          .finally(() => {
            this.loading = false;
          });
      }
    },
    /**
     * MODIFY PURCHASE ORDER
     */
    modifyPurchaseOrder() {
      this.loading = true;
      const modifyBrandPurchaseOrderBody = new this.$BcmModel.ModifyBrandPurchaseOrderBody(
        this.brand.purchaseOrder
      );
      this.$apiInstance
        .modifyBrandPurchaseOrder(
          this.$appConfig.currentBrand,
          modifyBrandPurchaseOrderBody
        )
        .then(
          (purchaseOrderData) => {
            this.getBrand();
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('SUCCESS',this.$t('PurchaseOrderSuccessfullyModified'));
            
          },
          (error) => {
            /**
             * ERROR MODIFY PURCHASE ORDER
             */
            this.$notification.notify('ERROR',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * ChECK IF BRAND NAME CHANGE
     */
    updateName(name) {
      this.brand.name = name;
      this.brandNameModified = true;
    },
    /**
     * ChECK IF PURCHASE ORDER CHANGE
     */
    updatePurchaseOrder(purchaseOrder) {
      this.brand.purchaseOrder = purchaseOrder;
      this.brandPurchaseOrderModified = true;
    },
    /**
     * ChECK IF BILLING ADDRESS CHANGE
     */
    updateAddress(address) {
      this.brand.address = address;
      this.brandAddressModified = true;
    },
  },
};
</script>
